// import axios from 'axios';
import axios from 'axios';
import cookie from 'react-cookies';
import { isExpired } from "react-jwt";
import { remoteToken, setToken } from './TokenManager';

export const checkToken = async (config) => {
  let accessToken = cookie.load('accessToken');
  let authorization = config.headers['Authorization'];

  if(!authorization && accessToken) {

    // 토큰 만료시간이 지났다면
    if(isExpired(accessToken)) {
      const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}/api/account/refresh`);
      if(data?.result?.accessToken) {
        setToken(data.result.accessToken);
        axios.defaults.headers.Authorization = `Bearer ${data.result.accessToken}`;
        config.headers['Authorization'] = `Bearer ${data.result.accessToken}`;
      } else {
        await axios.post(`${process.env.REACT_APP_API_HOST}/api/account/signOut`);
        remoteToken();
      }
    } else {
      axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  return config;
}