// theme.js
const colors = {
  borderFocus: '#90caf9',
  success: '#0f5132',
  borderSuccess: '#28a745',
  danger: 'red',
  errorBorder: '#dc3545',
  errorBorderFocus: '#dc3545',
  gray: '#ddd',
}
export const theme = {
  colors,
};

